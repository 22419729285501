import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery} from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import "./TipsStyles.scss"

const Tips = ()=>{
return (
  <section className="elementor-section elementor-top-section elementor-element elementor-element-cdbfe2b elementor-section-boxed elementor-section-height-default elementor-section-height-default">
    <div className="elementor-container elementor-column-gap-default">
      <div className="elementor-row">
        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2f8f2899">
          <div className="elementor-column-wrap elementor-element-populated">
            <div className="elementor-widget-wrap">
              <div className="elementor-element elementor-element-1225f1ec elementor-widget elementor-widget-gt3-core-designdraw">
                <div className="elementor-widget-container">
                  <div className="gt3_svg_line top">
                    <svg width="44px" height="200px" viewBox="0 0 44 200" preserveAspectRatio="none">
                      <g>
                        <path fill="rgba(255,255,255,1)" stroke="#ffffff" strokeWidth={1} strokeLinecap="butt" strokeLinejoin="miter" opacity={1} d="M 45.703505156528365 200.19885384998346 C 45.73301315307617 190.79171752929688 45.365841356073965 101.6338238086374 45.662750244140625 0.25295501947402954 C 45.52727381388346 10.878458460172016 41.63068771362305 22.985551555951435 34.041473388671875 36.57423400878906 C 24.181148529052734 56.518985748291016 1.116410493850708 75.28781127929688 0.9567615985870361 100.19087982177734 C 1.1245900392532349 125.75543975830078 23.82019805908203 145.14585876464844 34.17644691467285 165.19732666015625 C 41.07911682128906 178.5620574951172 45.73301315307617 190.79171752929688 45.703505156528365 200.19885384998346 Z" transform="matrix(1 0 0 1 -0.729369 -0.220138)" />
                      </g>
                    </svg>
                    <span className="gt3_svg_line_icon fa fa-arrow-down" />
                    <a className="gt3_svg_line_link" href="#gt3-next-section" />
                  </div>
                </div>
              </div>
              <div className="elementor-element elementor-element-5430ec29 elementor-widget elementor-widget-menu-anchor">
                <div className="elementor-widget-container">
                  <div id="gt3-next-section" className="elementor-menu-anchor" />
                </div>
              </div>
              <div className="elementor-element elementor-element-30476cd5 elementor-widget elementor-widget-spacer">
                <div className="elementor-widget-container">
                  <div className="elementor-spacer">
                    <div className="elementor-spacer-inner" />
                  </div>
                </div>
              </div>
              <div className="elementor-element elementor-element-1a7e4a05 elementor-widget elementor-widget-heading">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">La Agencia de Marketing Digital<br />comprometida con tu éxito</h2>
                </div>
              </div>
              <section className="elementor-section elementor-inner-section elementor-element elementor-element-d9a7a0e elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3cca3f20">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-57a1e433 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-gt3-core-imagebox" data-id="57a1e433" data-element_type="widget" data-widget_type="gt3-core-imagebox.default">
                            <div className="elementor-widget-container">
                               <div className="gt3-core-imagebox-wrapper tips_display elementor-image_icon-position-default">
                                <figure className="gt3-core-imagebox-img">
                                   <img className="attachment-full size-full" src={"/tips/rocket-icon.png"} alt="SEO" />
                                  </figure>
                                <div className="gt3-core-imagebox-content">
                                  <div className="gt3-core-imagebox-title">
                                    <h5 className="gt3-core-imagebox-title">Consultoría SEO</h5>
                                  </div>
                                  <p className="gt3-core-imagebox-description">Hacemos Auditoría SEO de tu web y detectamos todos los factores de mejora para llevar tu
                                    web al top.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-15912472">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-ddcf6f2 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-gt3-core-imagebox" data-id="ddcf6f2" data-element_type="widget" data-widget_type="gt3-core-imagebox.default">
                            <div className="elementor-widget-container">
                              <div className="gt3-core-imagebox-wrapper tips_display elementor-image_icon-position-default">
                                <figure className="gt3-core-imagebox-img">
                                    <img className="attachment-full size-full" src={"/tips/graphicon.png"} alt="SEO" />
                                </figure>
                                <div className="gt3-core-imagebox-content">
                                  <div className="gt3-core-imagebox-title">
                                    <h5 className="gt3-core-imagebox-title">Análisis de la Competencia</h5>
                                  </div>
                                  <p className="gt3-core-imagebox-description">Investigamos a tu competencia para detectar oportunidades de crecimiento para tu
                                    empresa.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-5a2e1f5b">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-4699f42e elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-gt3-core-imagebox">
                            <div className="elementor-widget-container">
                              <div className="gt3-core-imagebox-wrapper tips_display elementor-image_icon-position-default">
                                <figure className="gt3-core-imagebox-img">
                                    <img className="attachment-full size-full" src={"/tips/conversation-icon.png"} alt="SEO" />
                                </figure>
                                <div className="gt3-core-imagebox-content">
                                  <div className="gt3-core-imagebox-title">
                                    <h5 className="gt3-core-imagebox-title">Estrategia Social Media</h5>
                                  </div>
                                  <p className="gt3-core-imagebox-description">Construimos relaciones a largo plazo entre tu público y tu marca a través de las
                                    interacciones sociales.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
}

export default Tips 
