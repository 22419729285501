import React, {useEffect} from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"

import Footer from "../components/Footer/Footer.js"
import Home from "../templates/home.js"
import "../styles/GlobalStyles.css"

const Layout = ({ isHomePage, children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  console.log(children)

  useEffect(() => {
  document.body.className = 'elementor-kit-6604 elementor-page elementor-page-6218 page-done';
  if (isHomePage){
   return () => { document.body.className = ''; }
  }
}, [isHomePage]);
  
  return (
    <>
  <div class="main_wrapper"> 
    <div class="container container-sidebar_none">
         <div class="row sidebar_none">
            <div class="content-container span12">
               <section id="main_content">
                  <div class="elementor elementor-6218">
                     <div class="elementor-inner">
                        <div class="elementor-section-wrap"> 
        {isHomePage ? (
          <Home/>
        ) : (
          null
        )}
                            </div>
                     </div>
                  </div>
               </section>
            </div>
         </div>
      </div>
    </div>
    </>
  )
}

export default Layout
