import React from "react"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, useStaticQuery, graphql } from "gatsby"

import "./ServicesStyles.scss"

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1 
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Services = ()=>{

const ourServices = [
    {
        title: 'SEARCH ENGINE OPTIMIZATION – SEO',
        description: 'A través de una minuciosa investigación de palabras clave, podemos ayudarte a lograr las primeras posiciones en los resultados de los principales motores de búsqueda. Nuestra agencia SEO te ofrece servicios de auditoría SEO, optimización SEO técnico, marketing de contenidos, link building o SEO para ecommerce. ¡Incrementamos tu tráfico orgánico!',
        src: '/servicesIcon/SEO_TAGS.png'     
    },
    {
        title: 'DISEÑO DE PÁGINAS WEB & DESARROLLO',
        description: 'Existen millones de sitios en Internet, por ello debes asegurarte de que tu sitio web destaque, te represente adecuadamente y ayude a tu empresa a crecer. Desarrollamos webs a medida con las tecnologías más innovadoras y potentes: GatsbyJS, ReactJS, PHP, WordPress y Prestashop.',
        src: '/servicesIcon/WEB_DESIGN.png'
    },
      {
        title: 'PAGO POR CLIC - PPC',
        description: 'El SEO ofrece un crecimiento a largo plazo, pero a veces se necesita un impulso inmediato. Nuestras campañas de PPC garantizan que el dinero que gastas se optimiza al máximo para que obtengas el mejor ROI. Realizamos campañas PPC en Google Ads, Google Shopping, Facebook Ads, Instagram Ads, Display y Remarketing.',
        src: '/servicesIcon/PAY_PER_CLICK.png'
    },
      {
        title: 'SOCIAL MEDIA MARKETING',
        description: 'El marketing en redes sociales es fundamental para mantenerte conectado con tu audiencia. Te ayudamos a crear y a mantener tu comunidad social, generando contenidos interesantes que fomenten el engagement con tus potenciales clientes. Consigue una presencia digital que potencie tu branding y haz que tus seguidores te recuerden.',
        src: '/servicesIcon/SOCIAL_MEDIA.png'
    },
      {
        title: 'REDACCIÓN DE CONTENIDOS',
        description: 'Utilizamos el contenido para impulsar los procesos de conversión, generación de leads y SEO. Creamos textos que se posicionan en los primeros puestos de los motores de búsqueda e impulsan tus objetivos comerciales. Te ayudamos a ofrecer a tus usuarios contenidos relevantes y de calidad, útiles en sus búsquedas digitales.',
        src: '/servicesIcon/COPYWRITING.png'
    },
      {
        title: 'ANÁLISIS DE COMPETENCIA',
        description: 'Analizamos a tu competencia para detectar oportunidades de crecimiento para tu empresa. Hacemos benchmarking, para replicar aquello que los demás hacen bien y no repetir sus errores. Buscamos huecos de mercado no cubiertos por tu competencia en los que tengas oportunidad de crecimiento y trabajamos para que ocupes el nicho.',
        src: '/servicesIcon/INVESTMENT_SEARCH.png'
    },
    {
        title: 'INFORMES Y ANÁLISIS',
        description: 'Estudiamos detenidamente los resultados de nuestras acciones de marketing para optimizarlas al máximo y rentabilizar tu inversión. Analizamos las gráficas más importantes para tu negocio y observamos las consecuencias de nuestras acciones en ellas. Así podemos rectificar casi en tiempo real nuestras acciones de marketing para lograr el mejor rendimiento.',
        src: '/servicesIcon/ANALYTICS.png'
    },
    {
      title: 'MARKETING TRADICIONAL',
      description: 'Realizamos trabajos de Diseño Gráfico y Branding para posicionar tu marca y crear una imagen corporativa definida. Creamos campañas publicitarias en medios tradicionales y en soportes digitales. Siempre con una atención personalizada y con trabajos a medida que te diferencian de la competencia y se adaptan a tus necesidades reales.',
      src: '/servicesIcon/MARKETING.png'
    }

    
]


  const ourServicesCarousel = () =>{
    return (
    <>
      {ourServices.map((item, i) => (
  <div class="card" href="#">
    <div class="card-body">
      <div class="card-front">
        <img src={item.src} className="card-display__image attachment-full size-full" alt="Agencia de Marketing Online" />
        <h5 className="gt3-core-imagebox-title">{item.title}</h5>
      </div>
      <div class="card-back">
        <p className="gt3-core-imagebox-description">{item.description}</p>
      </div>
    </div>
</div>
))}
        </>
    )
  }

return (
    <>
  <section className="elementor-section elementor-top-section elementor-element elementor-element-3badb60f elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default">
  <div className="elementor-container elementor-column-gap-default">
    <div className="elementor-row">
      <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-be25f1d" data-id="be25f1d" data-element_type="column">
        <div className="elementor-column-wrap elementor-element-populated">
          <div className="elementor-widget-wrap">
            <section className="elementor-section elementor-inner-section elementor-element elementor-element-722e0dba elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="722e0dba" data-element_type="section">
              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-row">
                  <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-37d895ea" data-id="37d895ea" data-element_type="column">
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div className="elementor-element elementor-element-31e5ab54 elementor-widget elementor-widget-spacer" data-id="31e5ab54" data-element_type="widget" data-widget_type="spacer.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-da2558a elementor-widget elementor-widget-heading" data-id="da2558a" data-element_type="widget" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default">¿Por qué elegir Prensa y Media como tu agencia de marketing
                              digital?
                            </h2>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-6b699aaa elementor-widget elementor-widget-text-editor" data-id="6b699aaa" data-element_type="widget" data-widget_type="text-editor.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <p>En Prensa y Media Networks te ofrecemos soluciones de marketing digital que generan
                                ingresos para tu negocio:
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-743c49c1 elementor-widget elementor-widget-spacer" data-id="743c49c1" data-element_type="widget" data-widget_type="spacer.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-653332c5 elementor-position-left elementor-vertical-align-top elementor-widget elementor-widget-gt3-core-imagebox" data-id="653332c5" data-element_type="widget" data-widget_type="gt3-core-imagebox.default">
                          <div className="elementor-widget-container">
                            <div className="gt3-core-imagebox-wrapper elementor-image_icon-position-default">
                              <figure className="gt3-core-imagebox-img"><img width={68} height={68} src="https://livewp.site/wp/md/ewebot/wp-content/uploads/sites/64/2020/10/blue-chek-icon.png" className="attachment-full size-full" alt="" loading="lazy" title="blue-chek-icon" /></figure>
                              <div className="gt3-core-imagebox-content">
                                <p className="gt3-core-imagebox-description"><strong>Conseguimos resultados:</strong> No desperdicies tu dinero en acciones digitales que
                                  no generan conversiones. En Prensa y Media escuchamos tus necesidades y creamos
                                  estrategias a medida para alcanzar tus objetivos.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-743c49c1 elementor-widget elementor-widget-spacer" data-id="743c49c1" data-element_type="widget" data-widget_type="spacer.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-34aa0473 elementor-position-left elementor-vertical-align-top elementor-widget elementor-widget-gt3-core-imagebox" data-id="34aa0473" data-element_type="widget" data-widget_type="gt3-core-imagebox.default">
                          <div className="elementor-widget-container">
                            <div className="gt3-core-imagebox-wrapper elementor-image_icon-position-default">
                              <figure className="gt3-core-imagebox-img"><img width={68} height={68} src="https://livewp.site/wp/md/ewebot/wp-content/uploads/sites/64/2020/10/blue-chek-icon.png" className="attachment-full size-full" alt="" loading="lazy" title="blue-chek-icon" /></figure>
                              <div className="gt3-core-imagebox-content">
                                <p className="gt3-core-imagebox-description"><strong>Sabemos de Marketing Digital:</strong> Trabajar con una empresa de marketing digital que realmente conozca el marketing digital
                                  parece obvio, pero desafortunadamente, muchas empresas no logran obtener resultados
                                  positivos. Se ha demostrado que nuestras estrategias funcionan. De hecho, la mayoría de
                                  nuestros clientes amortizan su sitio web en los primeros dos meses logrando nuevos
                                  clientes como resultado directo de nuestros esfuerzos de marketing online y SEO.
                                </p>
                                <p />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-5d44bfaf elementor-widget elementor-widget-spacer" data-id="5d44bfaf" data-element_type="widget" data-widget_type="spacer.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-9f10f65 elementor-position-left elementor-vertical-align-top elementor-widget elementor-widget-gt3-core-imagebox" data-id="9f10f65" data-element_type="widget" data-widget_type="gt3-core-imagebox.default">
                          <div className="elementor-widget-container">
                            <div className="gt3-core-imagebox-wrapper elementor-image_icon-position-default">
                              <figure className="gt3-core-imagebox-img"><img width={68} height={68} src="https://livewp.site/wp/md/ewebot/wp-content/uploads/sites/64/2020/10/blue-chek-icon.png" className="attachment-full size-full" alt="" loading="lazy" title="blue-chek-icon" /></figure>
                              <div className="gt3-core-imagebox-content">
                                <p className="gt3-core-imagebox-description"><strong>Tú eres nuestra prioridad:</strong> Muchas agencias de marketing dicen enfocarse en el cliente, pero en la realidad muy pocas
                                  lo cumplen. Como agencia de SEO, Prensa y Media es diferente. Estamos realmente
                                  comprometidos con nuestros clientes. Hacemos nuestros tus objetivos y nos dejamos la piel
                                  para que consigas los mejores resultados.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-44859f30 elementor-widget elementor-widget-spacer" data-id="44859f30" data-element_type="widget" data-widget_type="spacer.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-34aa0473 elementor-position-left elementor-vertical-align-top elementor-widget elementor-widget-gt3-core-imagebox" data-id="34aa0473" data-element_type="widget" data-widget_type="gt3-core-imagebox.default">
                          <div className="elementor-widget-container">
                            <div className="gt3-core-imagebox-wrapper elementor-image_icon-position-default">
                              <figure className="gt3-core-imagebox-img"><img width={68} height={68} src="https://livewp.site/wp/md/ewebot/wp-content/uploads/sites/64/2020/10/blue-chek-icon.png" className="attachment-full size-full" alt="" loading="lazy" title="blue-chek-icon" /></figure>
                              <div className="gt3-core-imagebox-content">
                                <p className="gt3-core-imagebox-description"><strong>Somos honestos:</strong> Creemos en la honestidad, la integridad y el respeto a nuestros clientes. Nunca
                                  intentaremos "venderte" un servicio que no necesitas. Estudiamos tu caso concreto y te
                                  ofrecemos servicios adaptados a tus necesidades reales.
                                </p>
                                <p />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-680bb9da elementor-widget elementor-widget-spacer" data-id="680bb9da" data-element_type="widget" data-widget_type="spacer.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div className="cards">{ourServicesCarousel()}</div>
  </>
  )
}

export default Services
