import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery} from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Navbar from "../Navbar/Navbar.js"

const Hero = ()=>{

return (
  <section className="elementor-section elementor-top-section elementor-element elementor-element-3e58fee9 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
  <div className="elementor-container elementor-column-gap-no">
    <div className="elementor-row">
      <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3c6a0752">
        <div className="elementor-column-wrap elementor-element-populated">
          <div className="elementor-widget-wrap">
            <div className="elementor-element elementor-element-57670501 elementor-widget elementor-widget-spacer">
              <div className="elementor-widget-container">
                <div className="elementor-spacer">
                    <Navbar/>
                </div>
              </div>
            </div>
            <section className="elementor-section elementor-inner-section elementor-element elementor-element-40107c1b elementor-section-boxed elementor-section-height-default elementor-section-height-default">
              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-row">
                  <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5bf112a9">
                     <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div className="elementor-element elementor-element-43af9c02 elementor-pagination-position-outside elementor-widget elementor-widget-image-carousel">
                          <div className="elementor-widget-container">
                            <div className="elementor-image-carousel-wrapper swiper-container swiper-container-fade swiper-container-initialized swiper-container-horizontal">
                              <div className="elementor-image-carousel swiper-wrapper">
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-next swiper-slide-duplicate-prev">
                                  <figure className="swiper-slide-inner">
                                    <img className="swiper-slide-image" src="/hero/agencia-marketing-digital-pym.jpg" alt="Agencia de Marketing Online" />
                                  </figure>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-75e43da8">
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div className="elementor-element elementor-element-5c811c38 elementor-hidden-phone gt3_pulse_figure_element elementor-widget elementor-widget-image">
                          <div className="elementor-widget-container">
                            <div className="elementor-image">
                                <img className="attachment-full size-full" src={"/hero/gradient-figure.png"} alt="Agencia de Marketing Online" />
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-2d0b61bd elementor-widget elementor-widget-heading">
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default">Expertos en Crecimiento Digital</h2>
                          </div>
                        </div>
                        <div className="elementor-element-custom_font_size_tablet elementor-element-custom_font_size_mobile elementor-element-custom_color elementor-element elementor-element-2cef9ef elementor-widget elementor-widget-text-editor">
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <p>Impulsa tu negocio: consigue nuevos clientes potenciales con nuestros servicios de
                                marketing digital.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="gt3-core-button--alignment_inline elementor-element elementor-element-4769dfe elementor-widget elementor-widget-gt3-core-button">
                          <div className="elementor-widget-container">
                            <div className="gt3_module_button_elementor size_custom alignment_inline button_icon_none hover_none rounded">
                              <a className="button_size_elementor_custom alignment_inline border_icon_none hover_none btn_icon_position_left" href="#contact">
                                <span className="gt3_module_button__container">
                                  <span className="gt3_module_button__cover front"><span className="elementor_gt3_btn_text">Contacta con Nosotros</span></span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
      <section className="elementor-section elementor-inner-section elementor-element elementor-element-1952bf63 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row">
            <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-3536f4d7">
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div className="elementor-element elementor-element-46a5a9e8 elementor-widget elementor-widget-image">
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                          <img className="attachment-full size-full" src={"/hero/google-partner.png"} alt="SEO" />
                      </div>
                    </div>
                  </div>
                  <div className="elementor-element elementor-element-2eef8fe4 elementor-widget elementor-widget-spacer">
                    <div className="elementor-widget-container">
                      <div className="elementor-spacer">
                        <div className="elementor-spacer-inner" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-3536f4d7">
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div className="elementor-element elementor-element-46a5a9e8 elementor-widget elementor-widget-image">
                    <div className="elementor-widget-container">
                      <div className="elementor-image">
                        <img className="attachment-full size-full" src={"/hero/sales-force.png"} alt="SEO" />
                      </div>
                    </div>
                  </div>
                  <div className="elementor-element elementor-element-2eef8fe4 elementor-widget elementor-widget-spacer">
                    <div className="elementor-widget-container">
                      <div className="elementor-spacer">
                        <div className="elementor-spacer-inner" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-3536f4d7">
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div className="elementor-element elementor-element-46a5a9e8 elementor-widget elementor-widget-image">
                    <div className="elementor-widget-container">
                      <div className="elementor-image" style={{marginTop: '3rem'}}>
                          <img className="attachment-full size-full" src={"/hero/hubspot.png"} alt="SEO" />
                      </div>
                    </div>
                  </div>
                  <div className="elementor-element elementor-element-2eef8fe4 elementor-widget elementor-widget-spacer">
                    <div className="elementor-widget-container">
                      <div className="elementor-spacer">
                        <div className="elementor-spacer-inner" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
           </div>
        </div>
      </div>
    </div>
  </div>
</section>

)
}

export default Hero


