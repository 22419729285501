import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const About = ()=>{
  const Figure = "https://livewp.site/wp/md/ewebot/wp-content/uploads/sites/64/2020/10/home8_img_figure.png"
  const Image = "https://livewp.site/wp/md/ewebot/wp-content/uploads/sites/64/2020/10/businessman-using-smartphone.jpg"
  const SEOImage = "https://livewp.site/wp/md/ewebot/wp-content/uploads/sites/64/2020/10/seo_text.png"

return (
<section className="elementor-section elementor-top-section elementor-element elementor-element-474bfd42 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
  <div className="elementor-container elementor-column-gap-default">
    <div className="elementor-row">
      <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-707eb4e7">
        <div className="elementor-column-wrap elementor-element-populated">
          <div className="elementor-widget-wrap">
            <section className="elementor-section elementor-inner-section elementor-element elementor-element-13d556df elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="13d556df" data-element_type="section">
              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-row">
                  <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-3b73e6d" data-id="3b73e6d" data-element_type="column">
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div className="elementor-element elementor-element-27dd8917 elementor-widget elementor-widget-image animated fadeInLeft" data-id="27dd8917" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="image.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-image">
                              <StaticImage className="attachment-full size-full" src={Figure} alt="Agencia de Marketing Online" />
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-369569c1 gt3_image_custom_round elementor-widget elementor-widget-image" data-id="369569c1" data-element_type="widget" data-widget_type="image.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-image">
                              <StaticImage className="attachment-full size-full" src={Image} alt="Agencia de Marketing Online" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-35327531" data-id={35327531} data-element_type="column">
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div className="elementor-element elementor-element-67d8bc81 elementor-widget elementor-widget-spacer" data-id="67d8bc81" data-element_type="widget" data-widget_type="spacer.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element-custom_font_size elementor-element-custom_font_weight elementor-element elementor-element-6c515e7 elementor-widget elementor-widget-text-editor" data-id="6c515e7" data-element_type="widget" data-widget_type="text-editor.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <h5>Sobre Nosotros</h5>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-57eaaed1 elementor-widget elementor-widget-heading" data-id="57eaaed1" data-element_type="widget" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default">Agencia de marketing digital 360</h2>
                          </div>
                        </div>
                        <div className="elementor-element-custom_font_size elementor-element-custom_line_height elementor-element elementor-element-783209af elementor-widget elementor-widget-text-editor" data-id="783209af" data-element_type="widget" data-widget_type="text-editor.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <p>En Prensa y Media Networks ofrecemos un servicio integral, con una amplia gama de servicios de publicidad y marketing digital a clientes de todos los sectores desde 2010.</p>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-7a731b59 elementor-widget elementor-widget-text-editor" data-id="7a731b59" data-element_type="widget" data-widget_type="text-editor.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-text-editor elementor-clearfix">
                              <p>
                                Nuestra agencia está formada por un equipo de profesionales digitales altamente
                                cualificados, comprometidos con los objetivos de nuestros clientes. Somos honestos contigo
                                y te recomendamos siempre lo mejor para tu negocio teniendo en cuenta tus recursos.
                                Somos conscientes de que cada cliente es único y tiene unas necesidades concretas.
                                Nuestra meta es lograr resultados que optimicen tu negocio, acercándote al mundo del
                                marketing digital con claridad y transparencia, y generando valores tangibles.
                                Nuestros servicios de marketing digital incluyen trabajos de consultoría y administración
                                para una amplia variedad de prácticas de marketing online, incluyendo la optimización de
                                motores de búsqueda (SEO), posicionamiento SEM, anuncios de pago por clic (PPC),
                                gestión publicitaria en distintas plataformas de anuncios, redacción de contenidos, gestión
                                de redes sociales y más. También ofrecemos servicios de diseño y desarrollo web, tiendas
                                online y mantenimiento web para todo tipo de empresas.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-4fb423d5 elementor-widget elementor-widget-spacer" data-id="4fb423d5" data-element_type="widget" data-widget_type="spacer.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                       <div className="elementor-element elementor-element-292b2c18 elementor-widget elementor-widget-spacer" data-id="292b2c18" data-element_type="widget" data-widget_type="spacer.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-687da552 elementor-absolute elementor-widget elementor-widget-image" data-id="687da552" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-image">
                                <StaticImage className="attachment-full size-full" src={SEOImage} alt="Agencia de Marketing Online" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

)
}

export default About 
