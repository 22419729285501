import React from "react"
import CardBlog from "../Cards/CardBlog.js"

const Blog = ()=>{
return (
<section className="elementor-section elementor-top-section elementor-element elementor-element-561e95c2 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
  <div className="elementor-container elementor-column-gap-extended">
    <div className="elementor-row">
      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-51ef3aca" data-id="51ef3aca" data-element_type="column">
        <div className="elementor-column-wrap elementor-element-populated">
          <div className="elementor-widget-wrap">
            <div className="elementor-element elementor-element-4318ad2f elementor-widget elementor-widget-heading" data-id="4318ad2f" data-element_type="widget" data-widget_type="heading.default">
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">Blog de Marketing Digital</h2>
              </div>
            </div>
            <div className="elementor-element-custom_color elementor-element elementor-element-53ce2bd5 elementor-widget elementor-widget-text-editor" data-id="53ce2bd5" data-element_type="wdget" data-widget_type="text-editor.default">
              <div className="elementor-widget-container">
                <div className="elementor-text-editor elementor-clearfix">
                  <p>Noticias y novedades en el mundo del marketing digital: SEO, SEM, marketing de contenidos, redes sociales, Google y sus actualizaciones de algoritmo, herramientas utilizadas por profesionales y agencias de marketing digital... Ponte al día de lo último del sector digital en nuestro blog.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-158ce1b1" data-id="158ce1b1" data-element_type="column">
        <div className="elementor-column-wrap elementor-element-populated">
          <div className="elementor-widget-wrap" style={{justifyContent: "center"}}>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

)
}

export default Blog 
