import React from "react"
import {Helmet} from "react-helmet";

import Hero from "../components/Hero/Hero.js"
import Tips from "../components/Tips/Tips.js"
import About from "../components/About/About.js"
import Services from "../components/Services/Services.js"
import Blog from "../components/Blog/Blog.js"
import Contact from "../components/Contact/Contact.js"

import "../styles/GlobalStyles.css"

const Home = ()=>{
  return (
    <>
   <Helmet>
   <title>Agencia de Marketing Digital ▷ SEO, SEM, SMM | Prensa y Media</title>
   <meta name="description" content="Agencia de Marketing Digital Madrid: SEO, SEM, Diseño Páginas Web, Desarrollo, Social Media Marketing, Contenidos. 🚀 Impulsa tu negocio. ¡Contáctanos!" />
   <link rel="canonical" href="https://www.prensaymedia.es/" />
   </Helmet>
    <Hero/>
    <Tips/>
    <About/>
    <Services/>
    <Blog/>
    <Contact/>
    </>
  )
}

export default Home 

