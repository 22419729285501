import React, { useState, useEffect, useRef } from "react"
import { useFormik } from "formik"
import axios from "axios"
import "./ContactStyles.scss"

const Contact = ()=>{
    const WEBSITE_URL = process.env.ADM_PANEL_URL
    const FORM_ID = process.env.CONTACT_ID
    const [token, setToken] = useState("")
    const [isSuccessMessage, setIsSuccessMessage] = useState(false)
    const [messageSent, setMessageSent] = useState(false)

    const { handleChange, isSubmitting, values, handleSubmit } = useFormik({
    initialValues: {
      name: "",
      email: "",
      tel: "",
      message: "",
    },
    onSubmit: (
      { name, email, tel, message },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      const bodyFormData = new FormData()
      bodyFormData.set("your-name", name)
      bodyFormData.set("your-email", email)
      bodyFormData.set("your-tel", tel)
      bodyFormData.set("your-message", message)

      axios({
        method: "post",
        url: `${WEBSITE_URL}/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
        })
        .catch(error => {
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  useEffect(() => {
    setTimeout(() => {
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
  }, [isSuccessMessage, messageSent])


  const createContact = () =>{
    return(
    <form id="contact" className="topBefore" onSubmit={handleSubmit}>
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Nombre"
                  onChange={handleChange}
                  value={values.name}
                  required
                />
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={values.email}
                  required
                />
                           <input
                  id="tel"
                  name="tel"
                  placeholder="Número de Teléfono"
                  type="tel"
                  onChange={handleChange}
                  value={values.tel}
                  required
                /> 
                   <textarea
                  placeholder="Mensaje"
                  id="message"
                  name="message"
                  type="text"
                  onChange={handleChange}
                  value={values.message}
                  required
                /> 
               <button
              type="submit"
              value="Enviar"
              disabled={isSubmitting}
            >ENVIAR!</button>
    </form>
    )
  }

return (
  <>
<div className="elementor-element elementor-element-196b79e7 elementor-widget elementor-widget-spacer" data-id="196b79e7" data-element_type="widget" data-widget_type="spacer.default">
  <div className="elementor-widget-container">
    <div className="elementor-spacer">
      <div className="elementor-spacer-inner" />
    </div>
  </div>
</div>
<section className="elementor-section elementor-top-section elementor-element elementor-section-boxed elementor-section-height-default elementor-section-height-default">
  <div className="elementor-container elementor-column-gap-extended">
    <div className="elementor-row">
      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-51ef3aca" data-id="51ef3aca" data-element_type="column">
        <div className="elementor-column-wrap elementor-element-populated">
          <div className="elementor-widget-wrap">
            <div className="elementor-element elementor-element-4318ad2f elementor-widget elementor-widget-heading" data-id="4318ad2f" data-element_type="widget" data-widget_type="heading.default">
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default" style={{color: '#3b3663'}}>¿Preparado para crecer?<br />¡Solicita tu propuesta gratis ahora!</h2>
              </div>
            </div>
            <div className="elementor-element-custom_color elementor-element elementor-element-53ce2bd5 elementor-widget elementor-widget-text-editor" data-id="53ce2bd5" data-element_type="widget" data-widget_type="text-editor.default">
              <div className="elementor-widget-container">
                <div className="elementor-text-editor elementor-clearfix" style={{color: '#69668D'}}>
                  <p>Rellena el formulario y cuéntanos un poco más sobre tu negocio y tus expectativas con el marketing digital. Analizaremos tu caso y nos pondremos en contacto contigo para ofrecerte una propuesta personalizada, adaptada a tus necesidades reales. No esperes más... ¡Impulsa tu negocio!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-158ce1b1" data-id="158ce1b1" data-element_type="column">
        <div className="elementor-column-wrap elementor-element-populated">
          <div className="elementor-widget-wrap">
            {createContact()}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  <div className="elementor-element elementor-element-196b79e7 elementor-widget elementor-widget-spacer" data-id="196b79e7" data-element_type="widget" data-widget_type="spacer.default">
  <div className="elementor-widget-container">
    <div className="elementor-spacer">
      <div className="elementor-spacer-inner" />
    </div>
  </div>
</div>
 </>
)
}

export default Contact 
